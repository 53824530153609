<lib-dialog-title></lib-dialog-title>
<ng-container *ngIf="!showLoader">
  <div class="container-dialog">
    <h2 class="title" style="margin-top: 3rem">
      {{ 'add-new-affiliate-code' | translate }}
    </h2>
    <form (ngSubmit)="addNewCode()" [formGroup]="form">
      <nz-form-item>
        <nz-form-control [nzErrorTip]="errors.AffiliateCode">
          <input
            nz-input
            type="text"
            name="AffiliateCode"
            id="AffiliateCode"
            class="primary-input"
            formControlName="AffiliateCode"
            placeholder="Affiliate code"
          />
        </nz-form-control>
      </nz-form-item>

      <nz-form-item>
        <nz-form-control [nzErrorTip]="errors.companyName">
          <input
            nz-input
            type="text"
            name="companyName"
            id="companyName"
            maxlength='35'
            minlength='3'
            class="primary-input"
            formControlName="companyName"
            placeholder="Company name"
            [readOnly]="isCompanyName"
          />
        </nz-form-control>
      </nz-form-item>


      <nz-form-item>
        <nz-form-control [nzErrorTip]="errors.acceptAffiliateTermsConditions">
          <div class="checkbox-block">
            <input
              nz-input
              type="checkbox"
              name="acceptAffiliateTermsConditions"
              id="acceptAffiliateTermsConditions"
              class="primary-input"
              formControlName="acceptAffiliateTermsConditions"
            />
            <label
            >{{ 'i-have-read-and-agree-to-the' | translate }}
              <a target="_blank" href="/assets/download/TPT Affiliate T&C.pdf">{{
                'affiliate-terms-conditions' | translate
                }}</a></label
            >
          </div>
        </nz-form-control>
      </nz-form-item>

      <button type="submit" class="primary-btn sign-up">
        {{ 'become-an-affiliate' | translate }}
      </button>
    </form>
  </div>
</ng-container>
<lib-loading *ngIf="showLoader"></lib-loading>
