import { Component, OnInit } from '@angular/core';
import {
  ErrorFormService,
  ErrorsMap,
  IErrorMessage,
  IExistAffiliate,
  NotificationService,
  TypeMessage
} from 'repository';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { catchError, distinctUntilChanged, switchMap, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import { UserCabinetService } from './../../lib/user-cabinet.service';
import { MatDialogRef } from '@angular/material/dialog';
import { User } from 'user';

@UntilDestroy()
@Component({
  selector: 'lib-add-new-affiliate-code',
  templateUrl: './add-new-affiliate-code.component.html',
  styleUrls: ['./add-new-affiliate-code.component.scss']
})
export class AddNewAffiliateCodeComponent implements OnInit {
  public readonly errorMessages: ErrorsMap<IErrorMessage> = {
    AffiliateCode: {
      required: 'Affiliate code is required',
      codeIsExist:
        'This code is already taken, please insert a different code.',
      maxlength: 'Max length 30 symbol',
      pattern: 'Can contain only uppercase letters and numbers'
    },
    acceptAffiliateTermsConditions: {
      required: 'Please accept Affiliate Terms & Conditions'
    },
    companyName: {
      required: 'Company name cannot be empty',
      maxlength: 'Max length 35 symbol',
      minlength: 'Min length 3 symbol'
    }
  };

  form: FormGroup;
  public showLoader = false;

  public errors: ErrorsMap<string> = {};
  public affiliateErrorCode = false;

  constructor(
    private readonly _errorFormService: ErrorFormService,
    private readonly _userCabinetService: UserCabinetService,
    private readonly notification: NotificationService,
    private readonly _user: User,
    private readonly _dialogRef: MatDialogRef<AddNewAffiliateCodeComponent>,
    private readonly _formBuilder: FormBuilder
  ) {
  }

  public get isCompanyName(): boolean {
    return !!this._user?.companyName ?? false;
  }

  public ngOnInit(): void {
    this.buildForm();
  }

  public buildForm = (): void => {
    this.form = new FormGroup({
      AffiliateCode: new FormControl(
        null,
        [
          Validators.required,
          Validators.pattern('[A-Z0-9]*$'),
          Validators.maxLength(30)
        ]
      ),
      companyName: new FormControl(
        null,
        [
          Validators.required,
          Validators.maxLength(35),
          Validators.minLength(3),
          this._trim
        ]
      ),
      acceptAffiliateTermsConditions: new FormControl(
        false,
        [Validators.required, this._checkAcceptAffiliateTermsConditions]
      )
    });

    this.form
      .get('AffiliateCode')
      .setAsyncValidators(this.validateAffiliateCode);
    this.form
      .get('companyName')
      .setValue(this._user.companyName);

  };

  public addNewCode() {
    const form: FormGroup = this.form;

    this.form.get('companyName').setValue(this.form.get('companyName')?.value?.trim())

    if (this.affiliateErrorCode) {
      this.errors.AffiliateCode = this.errorMessages.AffiliateCode.codeIsExist;
    }

    if (!form.value.acceptAffiliateTermsConditions) {
      this.errors.acceptAffiliateTermsConditions = this.errorMessages.acceptAffiliateTermsConditions.required;
    }

    if (
      this.affiliateErrorCode ||
      form.invalid ||
      !form.value.acceptAffiliateTermsConditions
    ) {

      this.errors = this._errorFormService.verifyError(form, this.errorMessages);

      return;
    }

    if (this.form.get('companyName')?.hasError('minlength') ||
      this.form.get('companyName')?.hasError('required')) {
      this.errors.companyName = 'Min length 3 symbol';
      this.notification.showBlankNotification(
        'Company Name min length 3 symbol',
        TypeMessage.ERROR
      );

      return;

    }

    this.showLoader = true;

    this._userCabinetService.changeCompanyName(this.form.get('companyName').value, this._user.id)
      .pipe(untilDestroyed(this),
        distinctUntilChanged(),
        switchMap(() => {
            this._user.companyName = this.form.get('companyName').value;

            return this._userCabinetService.createNewCode(this.form.get('AffiliateCode').value);
          }
        ))
      .subscribe(
        () => {
          this.showLoader = false;
          this.notification.showBlankNotification(
            'Affiliate code successfully created',
            TypeMessage.SUCCESS
          );
          this._dialogRef.close();
        },
        (error) => {
          switch (error.error.errorCode) {
            case 3021:
              this.notification.showBlankNotification(
                'You cannot create more than 1 referral codes. ' +
                'Please contact support if you have any questions',
                TypeMessage.ERROR
              );
              break;
            default:
              this.notification.showBlankNotification(
                'Affiliate code already exist',
                TypeMessage.ERROR
              );
              break;
          }
          this._dialogRef.close();

          this.showLoader = false;
        }
      );
  }

  private readonly validateAffiliateCode = (control: AbstractControl) => {
    return this._userCabinetService.getReferral(control.value).pipe(
      untilDestroyed(this),
      catchError(() => of({} as IExistAffiliate)),
      tap((res) => {
        this.affiliateErrorCode = !!res?.id;
      })
    );
  };

  private readonly _trim = (
    control: FormControl
  ): ValidationErrors => {
    if (control?.value?.trim()?.length === 0) {
      control?.setValue(control?.value?.trim());

      return { required: true };
    } else {
      return null;
    }
  };

  private readonly _checkAcceptAffiliateTermsConditions = (
    control: FormControl
  ): ValidationErrors => {
    return control.value ? null : { notSame: true };
  };
}
