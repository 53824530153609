// `.env.ts` is generated by the `npm run env` command
// `npm run env` exposes environment variables as JSON for any usage you might
// want, like displaying the version or getting extra config from your CI bot, etc.
// This is useful for granularity you might need beyond just the environment.
// Note that as usual, any environment variables you expose through it will end up in your
// bundle, and you should not use it for any sensitive information like passwords or keys.
import { env } from './.env';

export const environment = {
  production: true,
  version: env.npm_package_version,
  sentryDsn: 'https://b103b03a0a704be293d98897640390f9@sentry.resadsoft.com/11',
  serverUrl: '/api',
  defaultLanguage: 'en-US',
  supportedLanguages: ['en-US', 'fr-FR'],
  identityUrl: './identity',
  accountsUrl: './accounts',
  spreedlyUrl: './spreedly',
  /*  accountsUrl: './accounts-tradovate',*/
  storeUrl: './store',
  paymentsUrl: './payments',
  spreedlyPayments: './SpreedlyPayments',
  promocodesUrl: './promocodes',
  bannersUrl: './banners',
  agreementsUrl: './agreements',
  notificationsUrl: './notifications',
  quotesUrl: './quotes',
  clientId: 'webclient',
  clientSecret: 'webclientSecret',
  scope:
    'openid profile email offline_access take-profit-store.api take-profit-accounts.api',
  stripe_key:
    'pk_live_51J0YKyBC8La3AgWwm672s9aQVwhmA2j1zBVDFrWGFqVzHvl5DPU5v5Ye8Rk2Nm9xoLFhaOga5bfqAmEy9z4qsZI3001rxz0WYM',
  plaid_clientId: '650a4675bd8162001bb39b95',
  plaid_secret: '82d6546ac3cb0450b6fa16bd0b3a74',
  plaid_template: 'flwtmp_aBY3x1c78Ckkkt',
  spreedly_stripe_gatway: 1,
  spreedly_nmi_gatway: 2,
  spreedly_stripe_apm: 5,
  stripe_arm_gateway: '7O23nZabQUEx3hVr7OB7j2PWQF3',
  nuveiMerchantId: '1164287968040039072',
  nuveiMerchantSiteId: '283796',
/*  nuveiMerchantId: '8972195453701179052',
  nuveiMerchantSiteId: '249918',*/
  captchaToken: '6LdwxswpAAAAAJqv6mnTM9DSgD6OEU-RykjQ-1J4',
};
